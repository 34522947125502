@font-face {
  font-family: kitKat;
  src: url("./fonts/KittyKatt.ttf");
}

body {
  margin: 0;
  font-family: kitKat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("../public/kitcatBackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
