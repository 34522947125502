.App {
  text-align: center;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
}

.image {
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
}

.main {
  width: 50%;
}

.chocolateImage {
  position: absolute;
  opacity: 0.7;
  z-index: 1;
}

a {
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 800px) {
  .main {
    width: 100%;
    padding: 0px 20px;
  }
}

@media screen and (max-width: 1000px) {
  .chocolateImage {
    display: none;
  }
}
